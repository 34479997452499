<template>
  <div class="content_thank">
    <div class="container">
      <h3 class="title_404">
        404
      </h3>
      <div class="like_block">
        <img alt="" src="/img/icon.svg" />
        <img alt="" src="/img/icon.svg" />
        <img alt="" src="/img/icon.svg" />
      </div>
      <img alt="" class="thank-img mw-100" src="/img/404.gif" />
      <p class="thank__order_id mb-0">
        OOPS!
      </p>
      <p class="delevery_info__404">
        <span>{{ $t("not_found_description") }}</span>
      </p>
      <div class="btn_home__404">
        <router-link :to="{ name: 'home' }">
          {{ $t("back_to_homepage") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.classList.add("favorits_page_restoran");
  },

  destroyed() {
    document.body.classList.remove("favorits_page_restoran");
  },

  metaInfo() {
    return {
      title: this.$t("not_found")
    };
  }
};
</script>

<!--suppress CssUnknownTarget -->
<style lang="scss" scoped>
@import "~@/assets/css/bootstrap.min.css";
@import "~@/assets/css/clear.css";
@import "~@/assets/css/jquery.formstyler.css";
@import "~@/assets/css/jquery.formstyler.theme.css";
@import "~@/assets/css/login.css";
@import "~@/assets/css/main.css";
@import "~@/assets/css/my_account.css";
@import "~@/assets/css/register.css";
@import "~@/assets/css/shopcart.css";
@import "~@/assets/css/media.css";
</style>
